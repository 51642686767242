import React, { useEffect, useState } from "react";
import "./ItemDetails.css";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../config/axsios.config";
import Loader from "../../components/Loader/Loader";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FaCartArrowDown } from "react-icons/fa";

export default function ItemDetails() {
    const navigate = useNavigate();

    const { lang } = useSelector((state) => state.lang);
    const { user } = useSelector((state) => state.user);
    const { logedIn } = useSelector((state) => state.user);

    const { id } = useParams();

    const { isLoading, data } = useAuthenticatedQuery({
        queryKey: ["item"],
        url: `api/item/${id}`,
        config: {},
    });

    if (isLoading) return <Loader />;

    const images = data.result.photo.map((photo) => ({
        original: photo.imgUrl,
        thumbnail: photo.imgUrl,
    }));

    const BuyNow = () => {
        if (!logedIn) {
            toast.error(
                lang === "en" ? "You have to login" : " يرجي تسجيل الدخول "
            );
            setTimeout(() => {
                navigate("/profile");
            }, 1500);
        } else {
            navigate(`/payment/${id}`);
        }
    };

    return (
        <section className="container-fluid">
            <div className="row my-5 single-item-container p-5 justify-content-around rounded-5">
                <div className="col-xl-6 h-100 mb-5">
                    <ImageGallery items={images} />
                </div>
                <div className="col-xl-5 d-flex flex-column justify-content-center gap-3">
                    <p className="fs-4">
                        <span className="fw-bold">
                            {lang === "en" ? "Name" : "الاسم"} :
                        </span>{" "}
                        {data.result.name}
                    </p>
                    <p>
                        <span className="fw-bold fs-4">
                            {lang === "en" ? "Description" : "الوصف"} :
                        </span>
                        &nbsp;
                        {data.result.disc}
                    </p>

                    <p className="fs-4">
                        <span className="fw-bold">
                            {lang === "en" ? "Price" : "السعر"} :
                        </span>
                        &nbsp;
                        {data.result.price} {data.result.currency}
                    </p>
                    <p>
                        <span className="fw-bold fs-4">
                            {lang === "en" ? "Type" : "النوع"} :
                        </span>
                        &nbsp;
                        <span className="fs-5 text-black-50">
                            {data.result.type}
                        </span>
                    </p>
                    <div>
                        {user.id != data.result.userId ? (
                            <button
                                className="btn btn-primary px-5 fs-5 fw-bold w-100 d-flex gap-1 justify-content-center"
                                onClick={() => BuyNow()}
                            >
                                <FaCartArrowDown />
                                {lang === "en" ? "Buy now" : "شراء الآن"}
                            </button>
                        ) : (
                            <h2 className="text-danger text-center">
                                {lang === "en"
                                    ? "That's your item"
                                    : "هذا هو عنصرك"}
                            </h2>
                        )}
                    </div>
                </div>
                <div>
                    <div>
                        <h2>
                            {lang === "en"
                                ? "Video for the item"
                                : " فيديو للعنصر "}
                        </h2>
                        <video
                            width="100%"
                            src={data.result.videoUrl}
                            controls
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
