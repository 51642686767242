import { forwardRef } from "react";

import "./TextArea.css";

const TextArea = forwardRef(({ error, ...otherAttr }, ref) => {
    return (
        <textarea
            ref={ref}
            className={`w-100 p-4 rounded-5 main-input d-block ${
                error ? "mt-4 mb-2 has-error" : "my-4"
            }`}
            rows="8"
            {...otherAttr}
        />
    );
});

export default TextArea;
