import React from "react";
import { ProductBid } from "./ProductBid";

export function Bid(props) {
  return (
    <>
      <ProductBid />
    </>
  );
}
