import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";

function TrainerDetails() {

    const {id} = useParams()

    const {token} = useSelector(state => state.user)
    const {lang} = useSelector(state => state.lang)

    const { isLoading, data, error } = useAuthenticatedQuery({
        url: `api/trainer/${id}`,
        queryKey: [`trainer${id}`],
        config: {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    });

    if (isLoading) return <Loader />;
    
    return (
        <section className="container-fluid">
        <div className="row my-5 single-item-container p-5 justify-content-around rounded-5">
            <div className="col-xl-6 h-100 mb-5">
                <img className="w-full" src={data.result.trnImgUrl} alt="" />
            </div>
            <div className="col-xl-5 d-flex flex-column justify-content-center gap-3">
                <p className="fs-4">
                    <span className="fw-bold">
                        {lang === "en" ? "Name" : "الاسم"} :
                    </span>{" "}
                    {data.result.fullName}
                </p>
                <p className="fs-4">
                    <span className="fw-bold">
                        {lang === "en" ? "Price" : "السعر"} :
                    </span>
                    &nbsp;
                    {data.result.rentmoney}$
                </p>
                <p>
                    <span className="fw-bold fs-4">
                        {lang === "en" ? "email" : "البريد الالكتروني"} :
                    </span>
                    &nbsp;
                    <span className="fs-5">
                        {data.result.email}
                    </span>
                </p>
                <p>
                    <span className="fw-bold fs-4">
                        {lang === "en" ? "phone" : "رقم الهاتف"} :
                    </span>
                    &nbsp;
                    <span className="fs-5">
                        {data.result.phone}
                    </span>
                </p>
                <p>
                    <a href={data.result.trnCV} target="_blank" className="fs-5">
                    {lang === "en" ? "CV" : "السيرة الذاتية"}
                        
                    </a>
                </p>
            </div>
        </div>
    </section>
    )
}

export default TrainerDetails
