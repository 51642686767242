import { useEffect } from "react";
import {
    Container,
    Nav,
    Navbar,
    NavbarCollapse,
    NavbarToggle,
} from "react-bootstrap";
import "./MainNav.css";
import { Link, NavLink } from "react-router-dom";
import Logo from "../Logo/Logo";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../slices/userSlice";
import { swapLang } from "../../slices/langSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { IoHome } from "react-icons/io5";
import { BsExclamationSquareFill } from "react-icons/bs";
import { MdMiscellaneousServices } from "react-icons/md";
import { GiJumpingDog, GiNothingToSay } from "react-icons/gi";
import { BiDollar } from "react-icons/bi";
import { RiMailSendLine, RiGitRepositoryPrivateFill } from "react-icons/ri";
import { GoLaw } from "react-icons/go";
import { ImProfile } from "react-icons/im";
import { HiOutlineReceiptRefund } from "react-icons/hi2";

import { MdElectricRickshaw } from "react-icons/md";
import { LiaFileContractSolid } from "react-icons/lia";
import AdsNav from "../AdsSide/AdsSide";

const NAVBAR_LINKS = [
    {
        text: "الرئيسية",
        en: "Home",
        to: "/",
        icon: <IoHome />,
    },
    {
        text: "من نحن",
        en: "About Us",
        to: "/about",
        icon: <BsExclamationSquareFill />,
    },
    {
        text: "خدماتنا",
        en: "Services",
        to: "/services",
        icon: <MdMiscellaneousServices />,
    },
    {
        text: "المزايدات",
        en: "Bidding",
        to: "/bidding",
        icon: <BiDollar />,
    },
    {
        text: "المدربين",
        en: "Trainers",
        to: "/trainers",
        icon: <GiJumpingDog />
    },
    {
        text: "قالوا عنا",
        en: "Testimonials",
        to: "/testimonials",
        icon: <GiNothingToSay />,
    },
    {
        text: "تواصل معنا",
        en: "Contact",
        to: "/contact",
        icon: <RiMailSendLine />,
    },
    {
        text: "الشروط والاحكام",
        en: "Terms & Conditions",
        to: "/terms&conditions",
        icon: <GoLaw />,
    },
    {
        text: "سياسة الخصوصية",
        en: "Privacy Policy",
        to: "/privacypolicy",
        icon: <RiGitRepositoryPrivateFill />,
    },
    {
        text: "سياسة التعاقد",
        en: "Contracting Policy",
        to: "/contractingpolicy",
        icon: <LiaFileContractSolid />,
    },
    {
        text: "فترة التسليم ",
        en: "Delivery Policy",
        to: "/deliveryperiod",
        icon: <MdElectricRickshaw />,
    },
    {
        text: "سياسة الاسترداد",
        en: "Refund Policy",
        to: "/refundpolicy",
        icon: <HiOutlineReceiptRefund />,
    },
];

export default function MainNav() {
    const { logedIn } = useSelector((state) => state.user);
    const { lang } = useSelector((state) => state.lang);
    const dispatch = useDispatch();

    useEffect(() => {
        const navHeight = document.querySelector(".navbar").clientHeight;
        document.querySelector("main").style.paddingTop = `${navHeight}px`;
    }, []);

    return (
        <Navbar
            className="navbar navbar-expand-lg navbar-light navStyle"
            style={{ paddingTop : "100" }}
            expand="lg"
        >
            <Container>
                <Logo />
                <div className="d-flex justify-content-between">
                <NavbarToggle aria-controls="navbarScroll" />
                <button
                    onClick={() => dispatch(swapLang())}
                    className="btn btn-primary rounded p-1 d-flex align-items-center mx-1 d-lg-none"
                >
                    {lang === "en" ? "عربية" : "English"}
                    <FontAwesomeIcon icon={faGlobe} className="mx-1" />
                </button>
            </div>
                <NavbarCollapse
                    id="navbarScroll"
                    style={{ padding: "10px 0", textAlign: "center" }}
                >
                    <Nav
                        className="m-auto my-2 my-lg-0 gap-3 gap-lg-0 align-items-center d-none d-lg-flex"
                        navbarScroll
                    >
                        {NAVBAR_LINKS.map(({ text, to, en, icon }, i) => {
                            if (i > 3) return;

                            return (
                                <NavLink
                                    key={text}
                                    className="ms-0 link nav-link lh-sm d-flex align-items-center gap-1"
                                    to={to}
                                >
                                    <div
                                        style={{
                                            fontSize: "1rem",
                                            marginTop: "-5px",
                                        }}
                                    >
                                        {icon}
                                    </div>
                                    {lang === "en" ? en : text}
                                </NavLink>
                            );
                        })}
                        <div className="position-relative mx-3">
                            <a
                                className="btn btn-primary dropdown-toggle more-btn pt-2"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                {lang === "en" ? "More" : "المزيد"}
                            </a>
                            <div
                                style={{ width: "230px" }}
                                className="dropdown-menu drop-down py-4"
                                aria-labelledby="dropdownMenuLink"
                            >
                                {NAVBAR_LINKS.map(
                                    ({ text, to, en, icon }, i) => {
                                        if (i < 4) return;
                                        return (
                                            <NavLink
                                                key={text}
                                                className="mx-auto my-1 link nav-link lh-sm d-flex align-items-center gap-1"
                                                to={to}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: "1rem",
                                                        marginTop: "-5px",
                                                    }}
                                                >
                                                    {icon}
                                                </div>
                                                {lang === "en" ? en : text}
                                            </NavLink>
                                        );
                                    }
                                )}
                                {logedIn && (
                                    <div className="d-flex gap-1 flex-row flex-nowrap">
                                        <NavLink
                                            className="link nav-link mx-auto"
                                            to="/profile"
                                        >
                                            <div
                                                style={{
                                                    fontSize: "1rem",
                                                    marginInlineEnd: "5px",
                                                    marginTop: "-5px",
                                                }}
                                            >
                                                <ImProfile />
                                            </div>
                                            {lang === "en"
                                                ? "Profile"
                                                : "الصفحه الشخصية"}
                                        </NavLink>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Nav>
                    <Nav
                        className="m-auto my-2 my-lg-0 gap-3 gap-lg-0 align-items-center d-lg-none"
                        navbarScroll
                    >
                        {NAVBAR_LINKS.map(({ text, en, to, icon }) => (
                            <div
                                key={text}
                                className="d-flex gap-1 flex-row flex-nowrap"
                            >
                                {icon}
                                <NavLink
                                    key={text}
                                    className="link nav-link"
                                    to={to}
                                >
                                    {lang == "en" ? en : text}
                                </NavLink>
                            </div>
                        ))}
                        <NavLink className="link nav-link" to="/profile">
                            الصفحه الشخصية
                        </NavLink>
                    </Nav>

                    <Link
                        to="/login"
                        onClick={() => {
                            if (logedIn) {
                                dispatch(logOut());
                            }
                        }}
                        className="login-btn text-decoration-underline"
                    >
                        {logedIn
                            ? lang === "en"
                                ? "Logout"
                                : "تسجيل الخروج"
                            : lang === "en"
                            ? "Login"
                            : "تسجيل الدخول"}
                        <i className="fa-regular fa-user"></i>
                    </Link>
                    <button
                    onClick={() => dispatch(swapLang())}
                    className="btn btn-primary rounded p-1 d-flex align-items-center mx-1 d-none d-lg-block"
                >
                    {lang === "en" ? "عربية" : "English"}
                    <FontAwesomeIcon icon={faGlobe} className="mx-1" />
                </button>
                </NavbarCollapse>
          
            </Container>
        </Navbar>
    );
}
