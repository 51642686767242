import React from "react";
import "./Home.css";
import Items from "./Items";
import Slider from "./Slider";
import { useSelector } from "react-redux";
import Advantages from "../../components/Advantages/Advantages";
import AboutSection from "../../components/AboutSection/AboutSection";

export default function Home() {
    const { lang } = useSelector((state) => state.lang);

    return (
        <>
            <Slider />
            <Items />
            <section className="about-section py-5 mt-5">
                <div className="about py-5">
                    <h1 className="text-center fw-bold">
                        {lang === "en" ? "About us" : "من نحن"}
                    </h1>
                </div>
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                         <AboutSection />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <img
                                src="about.png"
                                className="mx-auto d-block w-100"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 features-section ">
                <div className="container py-5">
                    <h2 className="text-center mb-5 fw-bold ">
                        {lang === "en" ? "Why Choose Us" : "ما يميزنا"}
                    </h2>
                    <div className="d-flex flex-wrap justify-content-between">
                        <Advantages />
                    </div>
                </div>
            </section>
        </>
    );
}
