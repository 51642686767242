import { Link, useNavigate } from "react-router-dom"
import { useForm, SubmitHandler } from "react-hook-form"
import { Fragment, useState } from "react"

import Input from "../../components/Input/Input"
import Button from "../../components/Button/Button"
import FormContainer from "../../components/FormContainer/FormContainer"
import InputErrorMessage from "../../components/InputErrorMessage/InputErrorMessage"

import { LOGIN_INPUTS } from "../../data"
import toast from "react-hot-toast"
import mainAxiosInstance from "../../config/axiosInstance"
import { useDispatch, useSelector } from "react-redux"
import { ImSpinner7 } from "react-icons/im";
import { addUserData, addUserToken } from "../../slices/userSlice"

function Login() {
const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);
    const {lang} = useSelector(state => state.lang)

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
 
            const { status , data: resData} = await mainAxiosInstance.post(
                "api/auth/login",
                data
            );
            if (status === 200) {
                toast.success( lang === "en" ? "Logged in successfully" : "لقد قمت بتسجيل الدخول بنجاح ", {
                    duration: 1500,
                    style: {
                        backgroundColor: "black",
                        color: "white",
                        width: "fit-content",
                    },
                });
                dispatch(addUserData(resData.result.user))
                dispatch(addUserToken(resData.result.token))
            }
        } catch (error) {
            console.log(error);
            const message = error.response?.data.message || "حدث خطأ غير معروف";
            toast.error(`${message}`, {
                position: "top-center",
                duration: 3000,
                style: {
                    direction: "ltr",
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        
        <FormContainer bg='login-bg'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {LOGIN_INPUTS.map(({type , rules , en , ar , id })=> (
                        <Fragment key={id}>
                        <div className="position-relative">
                        <Input
                        type={type}
                        {...register(id , rules)}
                        placeholder={lang === "en" ? en.placeholder : ar.placeholder}
                        error={errors[id] && true}
                        />
                        </div>
                            {errors[id] && <InputErrorMessage msg={lang === "en" ? en.validations[errors[id].type] : ar.validations[errors[id].type]} />}
                        </Fragment>
                    ))}
                    <Button width="100%" disabled={isLoading}>
                    {isLoading ? <ImSpinner7 className="spinner"/> : "تسجيل الدخول"}
                </Button>
                </form>
                <p className="mt-4 fw-bold">
                لم تقم بالتسجل بعد ؟
                <Link to="/register" className="text-decoration-none"> تسجيل مستخدم جديد</Link>
                </p>
        </FormContainer>

    )
}

export default Login
