import React from "react";
import "./Pagination.css";

const Pagination = ({ totalPosts, postsPerPage, setCurrentPage, currentPage }) => {
  const totalPages = Math.ceil(totalPosts / postsPerPage);
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="pagination mt-5 justify-content-center align-content-center">
      {currentPage > 1 && (
        <button
          className="p-0 pt-2 d-flex align-items-center justify-content-center btn btn-primary rounded-pill"
          onClick={goToPreviousPage}
        >
          <i className="bi bi-caret-right-square-fill"></i>
        </button>
      )}

      {pages.map((page) => (
        <button
          key={page}
          className={`pt-2 btn rounded-pill ${page === currentPage ? "btn-primary" : "btn-outline-primary"}`}
          onClick={() => goToPage(page)}
        >
          {page}
        </button>
      ))}

      {currentPage < totalPages && (
        <button
          className="p-0 pt-2 d-flex align-items-center justify-content-center btn btn-primary rounded-pill"
          onClick={goToNextPage}
        >
          <i className="bi bi-caret-left-square-fill"></i>
        </button>
      )}
    </div>
  );
};

export default Pagination;