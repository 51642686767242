import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Fragment, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import ItemCard from "../../../components/ItemCard/ItemCard";
import { useSelector } from "react-redux";
import useAuthenticatedQuery from "../../../hooks/useAuthenticatedQuery";
import Modal from "../../../components/Modal/Modal";
import mainAxiosInstance from "../../../config/axiosInstance";
import toast from "react-hot-toast";
import TrainerModal from "../../../components/TrainerModal/TrainerModal";

const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 6,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    arrows: false,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function MyBids() {
    const [currentItemId, setCurrentItemId] = useState(0);
    const { lang } = useSelector((state) => state.lang);
    const { user, token } = useSelector((state) => state.user);
    const [update, setUpdate] = useState(1);
    const deleteBid = async () => {
        try {
            await mainAxiosInstance.delete(`api/bid?id=${currentItemId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            toast.success(
                lang === "en"
                    ? `Item deleted from bids successfully`
                    : `تم مسح العنصر من المزايدات بنجاح`,
                {
                    position: "top-center",
                    duration: 1500,
                    style: {
                        backgroundColor: "black",
                        color: "white",
                        width: "fit-content",
                    },
                }
            );
            setUpdate((prev) => prev + 1);
        } catch (error) {
            console.log(error);
        }
    };

    const { isLoading, data } = useAuthenticatedQuery({
        queryKey: ["bids" , `bid${update}`],
        url: `api/bid/user/${user.id}`,
        config: {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    });

    if (isLoading) return <Loader />;
    const myBids = data.result.filter(
        (item) => item.isActive === true && item.items.isActive === true
    );
    const [currentItem] = myBids.filter(
        (item) => item.biddingNo === currentItemId
    );

    return (
        <Fragment>
            <h2 className="my-4">{lang === "en" ? "My bids" : "مزايداتي"} :</h2>
            <div className="items-section px-4 my-5">
                {myBids.length > 2 ? (
                    <Slider {...settings}>
                        {myBids.map((item) => (
                            <ItemCard
                                key={item.biddingNo}
                                item={item.items}
                                slider={true}
                                bid={true}
                                edits={true}
                                setCurrentItemId={setCurrentItemId}
                            />
                        ))}
                    </Slider>
                ) : (
                    <div className="d-flex flex-wrap">
                        {myBids.map((item) => (
                            <ItemCard
                                key={item.biddingNo}
                                biddingNo={item.biddingNo}
                                item={item.items}
                                bid={true}
                                edits={true}
                                setCurrentItemId={setCurrentItemId}
                            />
                        ))}
                    </div>
                )}
            </div>
            <TrainerModal />
            <Modal
                title={lang === "en" ? "Delete item" : "حذف العنصر"}
                id="deleteBid"
                footer={
                    <Fragment>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            {lang === "en" ? "Cancel" : "الغاء"}
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            data-bs-dismiss="modal"
                            onClick={() => deleteBid()}
                        >
                            {lang === "en" ? "Delete" : "حذف"}
                        </button>
                    </Fragment>
                }
            >
                <div className="modal-body">
                    <p>
                        {lang === "en"
                            ? `Are you sure you want to delete ${currentItem?.name} ?`
                            : `هل تريد حذف ${currentItem?.items?.name} ؟`}
                    </p>
                </div>
            </Modal>
        </Fragment>
    );
}

export default MyBids;
