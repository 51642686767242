import { useForm, SubmitHandler } from "react-hook-form";
import { Fragment, useState } from "react";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import InputErrorMessage from "../../components/InputErrorMessage/InputErrorMessage";
import TextArea from "../../components/TextArea/TextArea";
import InfoCard from "../../components/InfoCard/InfoCard";

import { CONTACT_INPUTS } from "../../data";
import { json, useNavigate } from "react-router-dom";

import "./Contact.css";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { ImSpinner7 } from "react-icons/im";
import mainAxiosInstance from "../../config/axiosInstance";

function Contact() {
  const { lang } = useSelector((state) => state.lang);
  const { logedIn, user } = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setLoader(true);
      const FakeId = new Date().getTime();
      const now = new Date().toISOString();
      const contact = {
        contactNo: 0,
        userId: user.id || 1,
        fullName: user.fullName || data.name,
        email: user.email || data.email,
        phone: user.phone || data.phone,
        subject: data.subject,
        contactMsg: data.message,
        readTime: now,
      };
      const response = await mainAxiosInstance.post("api/contact", contact);
      toast.success(
        lang === "en" ? "Message sent successfully" : "تم الارسال بنجاح",
        {
          duration: 1500,
          style: {
            backgroundColor: "black",
            color: "white",
            width: "fit-content",
          },
        }
      );
      setTimeout(() => {
        navigate("/");
      }, 500);
    } catch (error) {
      console.log(error);
      toast.error(lang === "en" ? "Something went wrong" : "حدث خطأ ما");
    } finally {
      setTimeout(() => setLoader(false), 500);
    }
  };

  return (
    <div className="contact-page">
      <section className="page-title align-items-center justify-content-center d-flex">
        <div className="container">
          <h1 className="text-center fw-bold text-light">
            {lang === "en" ? "Contact Us" : "تواصل معنا"}
          </h1>
        </div>
      </section>
      <section className="contact-form py-5">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {CONTACT_INPUTS.map(({ type, rules, en, ar, id, login }) => (
                <Fragment key={id}>
                  {type === "textarea" ? (
                    <div id={id}>
                      <TextArea
                        type={type}
                        {...register(id, rules)}
                        placeholder={
                          lang === "en" ? en.placeholder : ar.placeholder
                        }
                        error={errors[id] && true}
                      />
                      {errors[id] && (
                        <InputErrorMessage
                          msg={
                            lang === "en"
                              ? en.validations[errors[id].type]
                              : ar.validations[errors[id].type]
                          }
                        />
                      )}
                    </div>
                  ) : login || !logedIn ? (
                    type != "email" ? (
                      <div className="col-lg-6 fs-6" id={id}>
                        <Input
                          type={type}
                          {...register(id, rules)}
                          placeholder={
                            lang === "en" ? en.placeholder : ar.placeholder
                          }
                          error={errors[id] && true}
                        />
                        {errors[id] && (
                          <InputErrorMessage
                            msg={
                              lang === "en"
                                ? en.validations[errors[id].type]
                                : ar.validations[errors[id].type]
                            }
                          />
                        )}
                      </div>
                    ) : (
                      <div className="col-lg-12 fs-6" id={id}>
                        <Input
                          type={type}
                          {...register(id, rules)}
                          placeholder={
                            lang === "en" ? en.placeholder : ar.placeholder
                          }
                          error={errors[id] && true}
                        />
                        {errors[id] && (
                          <InputErrorMessage
                            msg={
                              lang === "en"
                                ? en.validations[errors[id].type]
                                : ar.validations[errors[id].type]
                            }
                          />
                        )}
                      </div>
                    )
                  ) : null}
                </Fragment>
              ))}
              <div className="contact-button mb-5">
                <Button width="180px" disabled={loader}>
                  {loader ? (
                    <ImSpinner7 className="spinner" />
                  ) : lang === "en" ? (
                    "Send"
                  ) : (
                    "ارسال"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section className="contact-info py-5 my-5 container gy-5">
        <div className="d-flex flex-wrap justify-content-between">
          <InfoCard
            title={lang === "en" ? "Phone" : "رقم الهاتف"}
            icon={<i className="bi bi-telephone-forward"></i>}
            link="tel:00201061159253"
            linkText="00201061159253"
          />
          <InfoCard
            title={lang === "en" ? "Phone" : "رقم الهاتف"}
            icon={<i className="bi bi-telephone-forward"></i>}
            link="tel:00201091160752"
            linkText="00201091160752"
          />
          <InfoCard
            title={lang === "en" ? "Email" : "البريد الألكتروني"}
            icon={<i className="fa-regular fa-envelope"></i>}
            link="mailto:mosalem72@gmail.com"
            linkText="mosalem72@gmail.com"
          />
          {/*
          <InfoCard
          title={lang === "en" ? "Address" : "العنوان"}
          icon={<i className="bi bi-geo-alt"></i>}
          link="https://www.google.com/maps/dir//Dubai+United+Arab+Emirates/@25.2048493,55.2707828,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x3e5f43496ad9c645:0xbde66e5084295162"
          linkText="lorem absum dolor sit amet, consectetur adipiscing el"
          />
        */ }
        </div>
      </section>
      <section className="map-section pt-5 mt-5">
        <iframe
          title="location"
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=dubai+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </section>
    </div>
  );
}

export default Contact;
