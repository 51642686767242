import { Fragment } from "react";

import ServiceCard from "../../components/ServiceCard/ServiceCard";

import { SERVICESCARDS } from "../../data";

import "./Services.css";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import { Link } from "react-router-dom";

export default function Services() {
    const { lang } = useSelector((state) => state.lang);

    const { isLoading, data } = useAuthenticatedQuery({
        queryKey: ["services"],
        url: "api/services",
        config: {},
    });

    if (isLoading) return <Loader />;

    return (
        <Fragment>
            <section className="service">
                <div className="page-title title-box text-center align-items-center justify-content-center d-flex">
                    <h2 className="text-center fs-1 fw-bold text-light">
                        {lang === "en" ? "Our Services" : "خدماتنا"}
                    </h2>
                </div>
                <div className="container d-flex justify-content-center">
                    <Link
                        className="btn btn-outline-primary py-3 px-5"
                        to="/trainers"
                    >
                        {lang === "en" ? "Look At Trainers" : " رؤية المدربين"}
                    </Link>
                </div>
                <div className="container my-5">
                    <div className="row justify-content-between">
                        {data.result.map((service) => (
                            <ServiceCard
                                key={service.subTitleEn}
                                title={
                                    lang === "en"
                                        ? service.subTitleEn
                                        : service.subTitleAr
                                }
                                description={
                                    lang === "en"
                                        ? service.parphEn
                                        : service.parphAr
                                }
                            />
                        ))}
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
