import { useSelector } from "react-redux";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import Loader from "../Loader/Loader";

function TrainerPayment({ id }) {
    const { lang } = useSelector((state) => state.lang);
    const { token } = useSelector((state) => state.user);

    const { isLoading, data, error } = useAuthenticatedQuery({
        url: `api/trainer/${id}`,
        queryKey: [`trainerPay${id}`],
        config: {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    });

    if (isLoading) return <Loader />;
    return (
        <div
            className="d-flex flex-column"
            style={{ maxWidth: "400px", minWidth: "300px" }}
        >
            <h3 className="text-center p-3">
                {lang === "en" ? "trainer details" : "تفاصيل المدرب"}
            </h3>
            <div className="item-details card d-flex">
                <div className="image-box">
                    <img
                        src={data.result.trnImgUrl}
                        alt="data.result.name"
                        className="w-100"
                    />
                </div>
                <div className="d-flex justify-content-center pt-3">
                    <h5>{lang === "en" ? "Name" : "الاسم"} : </h5>
                    <h3>{data.result.fullName}</h3>
                </div>
                <div className="d-flex justify-content-center pt-3">
                    <h5>{lang === "en" ? "Price" : "السعر"} : </h5>
                    <h3>{data.result.rentmoney}$</h3>
                </div>
            </div>
        </div>
    );
}

export default TrainerPayment;
