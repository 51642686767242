import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import "./FeedBack.css";
import Input from "../../components/Input/Input";
import InputErrorMessage from "../../components/InputErrorMessage/InputErrorMessage";
import TextArea from "../../components/TextArea/TextArea";
import { Fragment, useState } from "react";
import { CONTACT_INPUTS, FEED_BACK_INPUTS } from "../../data";
import Button from "../../components/Button/Button";
import { ImSpinner7 } from "react-icons/im";
import mainAxiosInstance from "../../config/axiosInstance";
import toast from "react-hot-toast";

function FeedBack() {
    const { lang } = useSelector((state) => state.lang);
    const { user , token} = useSelector((state) => state.user);
    const [loader, setLoader] = useState(false);
    
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        try {
            setLoader(true);
            const feedback = {
                fbId: 0,
                userId: user.id,
                email: user.email,
                text: data.message,
            };
            const response = await mainAxiosInstance.post("api/feedback", feedback , {
                headers: { Authorization: `Bearer ${token}` },
            });
            console.log(response);
            toast.success(
                lang === "en" ? "Message sent successfully" : "تم الارسال بنجاح",)
reset()
            } catch (err) {
                toast.error(err.message);
            } finally {
                setLoader(false);
            }
    }

    return (
        <div className="feedback-page">
            <section className="page-title align-items-center justify-content-center d-flex">
                <div className="container">
                    <h1 className="text-center fw-bold text-light">
                        {lang === "en" ? "FeedBack" : "تقييم"}
                    </h1>
                </div>
            </section>
            <section className="contact-form py-5">
                <div className="container">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            {FEED_BACK_INPUTS.map(
                                ({ type, rules, en, ar, id }) => (
                                    <Fragment key={id}>
                                            <div id={id}>
                                                <TextArea
                                                    type={type}
                                                    {...register(id, rules)}
                                                    placeholder={
                                                        lang === "en"
                                                            ? en.placeholder
                                                            : ar.placeholder
                                                    }
                                                    error={errors[id] && true}
                                                />
                                                {errors[id] && (
                                                    <InputErrorMessage
                                                        msg={
                                                            lang === "en"
                                                                ? en
                                                                      .validations[
                                                                      errors[id]
                                                                          .type
                                                                  ]
                                                                : ar
                                                                      .validations[
                                                                      errors[id]
                                                                          .type
                                                                  ]
                                                        }
                                                    />
                                                )}
                                            </div>
                                    </Fragment>
                                )
                            )}
                            <div className="contact-button mb-5">
                                <Button width="180px" disabled={loader}>
                                    {loader ? (
                                        <ImSpinner7 className="spinner" />
                                    ) : lang === "en" ? (
                                        "Send"
                                    ) : (
                                        "ارسال"
                                    )}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
}

export default FeedBack;
