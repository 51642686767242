import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Fragment, useState } from "react";
import Select from "react-select";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import FormContainer from "../../components/FormContainer/FormContainer";
import InputErrorMessage from "../../components/InputErrorMessage/InputErrorMessage";

import { REGISTER_INPUTS } from "../../data";
import mainAxiosInstance from "../../config/axiosInstance";
import toast from "react-hot-toast";
import { ImSpinner7 } from "react-icons/im";
import { useSelector } from "react-redux";

function Register() {
    const { lang } = useSelector((state) => state.lang);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            const register = {
                id: 0,
                FullName: data.name,
                phone: data.phone,
                email: data.email,
                typeId: [data.accRole.value],
                password: data.password,
                isActive: true,
                passportNumber: data.passportNumber,
            };
            const { status } = await mainAxiosInstance.post(
                "api/auth/register",
                register
            );
            if (status === 200) {
                toast.success("لقد قمت بالتسجيل بنجاح ", {
                    position: "top-center",
                    duration: 1500,
                    style: {
                        backgroundColor: "black",
                        color: "white",
                        width: "fit-content",
                    },
                });

                setTimeout(() => navigate("/login"), 2000);
            }
        } catch (error) {
            console.log(error);
            const message =
                error.response?.data.message || "حدث خطأ غير معروف";
            toast.error(`${message}`, {
                position: "top-center",
                duration: 3000,
                style: {
                    direction: "ltr",
                },
            });
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <FormContainer bg="register-bg">
            <form onSubmit={handleSubmit(onSubmit)}>
                {REGISTER_INPUTS.map(({ type, rules, en, ar, id }) => (
                    <Fragment key={id}>
                        <div className="position-relative">
                            <Input
                                type={type}
                                {...register(id, rules)}
                                placeholder={
                                    lang === "en"
                                        ? en.placeholder
                                        : ar.placeholder
                                }
                                error={errors[id] && true}
                            />
                        </div>
                        {errors[id] && (
                            <InputErrorMessage
                                msg={
                                    lang === "en"
                                        ? en.validations[errors[id].type]
                                        : ar.validations[errors[id].type]
                                }
                            />
                        )}
                    </Fragment>
                ))}
                <Controller
                    name="accRole"
                    control={control}
                    defaultValue={null}
                    rules={{
                        required:
                            lang === "en"
                                ? "Account Type is required"
                                : "من فضلك اختار نوع الحساب",
                    }}
                    render={({ field }) => (
                        <Select
                            className={`custom-select mb-2 ${
                                errors.accRole && "error"
                            }`}
                            {...field}
                            options={[
                                {
                                    value: 1,
                                    label: lang === "en" ? "Bidder" : "مزايد",
                                },
                                {
                                    value: 2,
                                    label: lang === "en" ? "Seller" : "بائع",
                                },
                                {
                                    value: 3,
                                    label: lang === "en" ? "Buyer" : "مشتري",
                                },
                            ]}
                            defaultValue={{
                                value: 1,
                                label: lang === "en" ? "Bidder" : "مزايد",
                            }}
                            placeholder={
                                lang === "en"
                                    ? "Account Type"
                                    : "اختار نوع الحساب"
                            }
                        />
                    )}
                />
                {errors.accRole && (
                    <InputErrorMessage msg={errors.accRole.message} />
                )}

                <Button width="100%" disabled={isLoading}>
                    {isLoading ? (
                        <ImSpinner7 className="spinner" />
                    ) : lang === "en" ? (
                        "Register"
                    ) : (
                        "تسجيل"
                    )}
                </Button>
            </form>
            <p className="mt-4 fw-bold">
                {lang === "en"
                    ? "Already have an account ? "
                    : " هل تملك حساب بالفعل ؟"}
                <Link to="/login" className="text-decoration-none">
                    {lang === "en" ? "Login" : "تسجيل الدخول"}
                </Link>
            </p>
        </FormContainer>
    );
}

export default Register;
