import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

function PaymentDone() {
    const { lang } = useSelector((state) => state.lang);
    const {id} = useParams()

    console.log(id)
    return (
        <div className="w-full min-h-screen flex flex-col items-center justify-center gap-5 bg-lime-200">
            <h2 className="fw-bold text-5xl">
                {lang === "en"
                    ? "Payment has been completed successfully"
                    : "تم إتمام عملية الدفع بنجاح"}
            </h2>
            <div className="max-w-80 rounded-full overflow-hidden">
                <img className="w-full" src="https://greenlinesr-001-site3.anytempurl.com/Payment/PaymentDone.jpg" alt={lang==="en" ? "paymentDone"  : "تم الدفع"} />
            </div>
            <Link className="text-2xl no-underline" to="/">{lang === "en" ? "Back To Home" : "العودة للصفحة الرئيسية"}</Link>
        </div>
    );
}

export default PaymentDone;
