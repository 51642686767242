import { Link } from "react-router-dom"
import './PageNotFound.css'
import { useSelector } from "react-redux"

function PageNotFound() {
    const {lang} = useSelector(state => state.lang)
    return (
        <div className="vh-100 d-flex align-items-center justify-content-center page-not-found">
            <div>
                <p className="fs-1 text-center d-block fw-bold">{lang === "en" ? "Page Not Found" :  "الصفحة ليست موجودة"}</p>
                <p>4
                    <Link to="/">
                        <img
                        alt="Logo"
                        src="https://pngimg.com/uploads/camel/camel_PNG23404.png"
                        />
                    </Link>
                    4 
                </p>
                <Link className="d-block fs-1 text-center text-decoration-none fw-bold" to="/">{lang === "en" ? "Back To Home Page" :"الرجوع للصفحة الرئيسية"}</Link>
            </div>
        </div>
    )
}

export default PageNotFound
