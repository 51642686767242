import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";

export default function About() {
  const { lang } = useSelector((state) => state.lang);

  const { isLoading, data } = useAuthenticatedQuery({
    queryKey: ["about"],
    url: "api/about",
    config: {},
  });
  useEffect(() => {
    if (isLoading) return;
    const about = new DOMParser().parseFromString(
      lang === "en" ? data.result.textEn : data.result.textAr,
      "text/html"
    );
    document.getElementById("about").innerHTML = about.body.innerHTML;
  }, [data, lang]);

  if (isLoading) return <Loader />;

  return (
    <Fragment>
        <div id="about" className="text-center my-4"></div>
    </Fragment>
  );
}