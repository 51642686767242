import { RouterProvider } from "react-router-dom";
import router from "./routes";
import "./App.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUserData, logOut } from "./slices/userSlice";
import Loader from "./components/Loader/Loader";
import mainAxiosInstance from "./config/axiosInstance";

function App() {
    const [isLoader, setIsLoader] = useState(true);
    const { token, updates } = useSelector((store) => store.user);
    const { lang } = useSelector((store) => store.lang);
    const dispatch = useDispatch();

    useEffect(() => {
        document.querySelector("body").style.direction = `${
            lang === "en" ? "ltr" : "rtl"
        }`;
    }, [lang]);

    const getUserData = async (token) => {
        try {
            const response = await mainAxiosInstance.get("api/user", {
                headers: { Authorization: `Bearer ${token}` },
            });
            const {data} = response
            if(data.isSuccess) {
                dispatch(addUserData(data.result))
            } else {
                dispatch(logOut())
            }
        } catch (error) {
            console.log(error);
            if (error.message ===  "Request failed with status code 401" ) dispatch(logOut())
        } finally {
            setIsLoader(false);
        }
    };
    useEffect(() => {
        // if (token) 
        getUserData(token);
    }, []);

    if (isLoader) return <Loader />;

    return <RouterProvider router={router} />;
}

export default App;
