import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination/Pagination";
import FilterButton from "../../components/FilterButton/FilterButton";
import ItemCard from "../../components/ItemCard/ItemCard";
import axiosInstance from "../../config/axsios.config";
import Loader from "../../components/Loader/Loader";
import mainAxiosInstance from "../../config/axiosInstance";
import { Link, json } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoAddCircleSharp } from "react-icons/io5";
export function ProductBid() {
    const [allAnimals, setAllAnimals] = useState([]);
    const [animalsData, setAnimalsData] = useState([]);
    const [animalsTypes, setAnimalsTypes] = useState([]);
    const [active, setActive] = useState("all");
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(8);
    const [isLoading, setIsLoading] = useState(true);

    const fetchAnimalsData = async () => {
        try {
            const response = await mainAxiosInstance.get("api/bid/active");
            const types = [
                ...new Set(
                    response.data.result.map((animal) => animal.items.type)
                ),
            ];
            setAllAnimals(response.data.result);
            setAnimalsData(response.data.result);
            setAnimalsTypes(types);
            setTimeout(() => setIsLoading(false), 500);
        } catch (error) {
            console.error("Error fetching animal data:", error);
        } finally {
            setTimeout(() => setIsLoading(false), 500);
        }
    };

    useEffect(() => {
        fetchAnimalsData();
    }, []);

    useEffect(() => {
        fetchAnimalsData();
    }, []);

    const lastPostIndex = currentPage * postsPerPage;
    const firstPostIndex = lastPostIndex - postsPerPage;
    const currentPosts = animalsData.slice(firstPostIndex, lastPostIndex);

    const { lang } = useSelector((state) => state.lang);
    if (isLoading) return <Loader />;
    const handleFilter = (type) => {
        if (type === active) return;
        setAnimalsData(
            type === "all"
                ? allAnimals
                : allAnimals.filter((animal) => animal.items.type === type)
        );
        setActive(type);
        setCurrentPage(1);
    };

    return (
        <section className="items-page" style={{ marginTop: "30px" }}>
            <h1 className="text-center mb-2">المزايدات</h1>
            <div className="container">
                {
                    <Link to="/addItem" className="btn btn-outline-primary">
                        {lang == "ar" ? " اضافة عنصر " : " Add Item "}
                        <IoAddCircleSharp />
                    </Link>
                }
            </div>
            <div className="btns mb-2 d-flex justify-content-center">
                <FilterButton
                    isActive={active === "all"}
                    onClick={() => handleFilter("all")}
                >
                    {lang == "ar" ? "الجميع" : "All"}
                </FilterButton>
                {animalsTypes.map((type) => (
                    <FilterButton
                        key={type}
                        isActive={active === type}
                        onClick={() => handleFilter(type)}
                    >
                        {type}
                    </FilterButton>
                ))}
            </div>

            <section className="items-section">
                <div className="container">
                    <div className="items-section-blog row">
                        {currentPosts.map((item) => (
                            <ItemCard
                                key={item.biddingNo}
                                item={item.items}
                                bid={true}
                                biddingNo={item.biddingNo}
                            ></ItemCard>
                        ))}
                    </div>

                    <Pagination
                        totalPosts={animalsData.length}
                        postsPerPage={postsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            </section>
        </section>
    );
}
