import { Controller, useForm } from "react-hook-form";
import { Fragment, useState } from "react";

import Input from "../../components/Input/Input";
import InputErrorMessage from "../../components/InputErrorMessage/InputErrorMessage";
import UpLoadLabel from "../../components/UpLoadLabel/UpLoadLabel";
import TextArea from "../../components/TextArea/TextArea";
import Button from "../../components/Button/Button";

import { ADD_BID } from "../../data";

import "./AddItem.css";
import { useSelector } from "react-redux";
import mainAxiosInstance from "../../config/axiosInstance";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "react-select";
import axios from "axios";

const currencies = [
    {
        value: "$",
        label: "$",
    },
    {
        value: "€",
        label: "€",
    },
    {
        value: "EGP",
        label: "EGP",
    },
    {
        value: "SAR",
        label: "SAR",
    },
    {
        value: "AED",
        label: "AED",
    },
];
export default function AddItem() {
    const { lang } = useSelector((state) => state.lang);
    const { user, token } = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const [preview, setPreview] = useState({});

    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            currencyType: currencies[0],
        },
    });
    const printChange = (e, id) => {
        const file = [...e.target.files].map((file) => file);
        setPreview({ ...preview, [id]: file });
    };
    const onSubmit = async (data) => {
        try {
            setIsLoading(true);

            const itemDetails = new FormData();
            itemDetails.append("UserId", user.id);
            itemDetails.append("Name", data.name);
            itemDetails.append("Type", data.type);
            itemDetails.append("Disc", data.description);
            itemDetails.append("ItemPhysical.body", data.body);
            itemDetails.append("ItemPhysical.eye", data.eye);
            itemDetails.append("ItemPhysical.foot", data.foot);
            itemDetails.append("ItemPhysical.front", data.front);
            itemDetails.append("ItemPhysical.back", data.back);
            itemDetails.append("History", data.history);
            itemDetails.append("Price", data.price);
            itemDetails.append("Currency", data.currencyType.value);
            itemDetails.append("Health", "Health");
            itemDetails.append("CheckedDate", "1/1/2023");
            itemDetails.append("Images", preview.img[0]);
            itemDetails.append("Video", preview.video[0]);
            [...preview.imgs].forEach((file) => {
                itemDetails.append("Images", file);
            });

            const response = await mainAxiosInstance.post(
                "api/item",
                itemDetails,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response?.data.result === "Item Created") {
                toast.success(
                    lang === "en"
                        ? "Item added successfully"
                        : "تمت اضافة العنصر بنجاح",
                    {
                        position: "top-center",
                        duration: 500,
                        style: {
                            backgroundColor: "black",
                            color: "white",
                            width: "fit-content",
                        },
                    },
                    setTimeout(() => {
                        navigate("/profile");
                    }, 1500)
                );
            }
        } catch (error) {
            toast.error(error.response.data.error);
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <section className="add-bid page-title align-items-center justify-content-center d-flex">
                <div className="container">
                    <h2 className="text-light fw-bold text-center">
                        {lang === "en" ? "Add Item" : " اضافة عنصر "}
                    </h2>
                </div>
            </section>
            <div className="container my-5">
                <form
                    encType="multipart/form-data"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="row px-4">
                        {ADD_BID.map(
                            ({
                                type,
                                rules,
                                en,
                                ar,
                                placeholder,
                                id,
                                multiple,
                            }) => (
                                <div
                                    className={
                                        type === "textarea" ? "" : "col-lg-6"
                                    }
                                    key={id}
                                >
                                    {type === "file" ? (
                                        <Fragment>
                                            <UpLoadLabel
                                                htmlFor={id}
                                                error={errors[id] && true}
                                            >
                                                {lang === "en"
                                                    ? en.placeholder
                                                    : ar.placeholder}
                                            </UpLoadLabel>
                                            <Input
                                                type={type}
                                                id={id}
                                                multiple={multiple}
                                                {...register(id, rules)}
                                                placeholder={placeholder}
                                                error={errors[id] && true}
                                                className="d-none"
                                                onChange={(e) =>
                                                    printChange(e, id)
                                                }
                                                accept={
                                                    id === "video"
                                                        ? ".mp4, .mov, .avi, .wmv, .mkv, .flv, .mpeg, .3gp, .webm, .m4"
                                                        : ".jpg, .jpeg, .png, .gif, .bmp, .tiff, .tif, .webp, .svg"
                                                }
                                            />
                                            <ul>
                                                {preview[id]
                                                    ? preview[id].map(
                                                          (item) => (
                                                              <li
                                                                  key={
                                                                      item.name
                                                                  }
                                                              >
                                                                  {item.name}
                                                              </li>
                                                          )
                                                      )
                                                    : null}
                                            </ul>
                                        </Fragment>
                                    ) : type === "textarea" ? (
                                        <TextArea
                                            type={type}
                                            {...register(id, rules)}
                                            placeholder={
                                                lang === "en"
                                                    ? en.placeholder
                                                    : ar.placeholder
                                            }
                                            error={errors[id] && true}
                                        />
                                    ) : id === "price" ? (
                                        <div className="relative">
                                            <Input
                                                type={type}
                                                id={id}
                                                {...register(id, rules)}
                                                placeholder={
                                                    lang === "en"
                                                        ? en.placeholder
                                                        : ar.placeholder
                                                }
                                                error={errors[id] && true}
                                            />
                                            <div
                                                style={{ marginTop: "1.5px" }}
                                                className={`absolute top-0 w-24 ${
                                                    lang === "en"
                                                        ? "right-[2px]"
                                                        : "left-[2px]"
                                                }`}
                                            >
                                                <Controller
                                                    name="currencyType"
                                                    control={control}
                                                    defaultValue={null}
                                                    rules={{
                                                        required:
                                                            lang === "en"
                                                                ? "currency Type is required"
                                                                : "من فضلك اختار نوع العملة",
                                                    }}
                                                    render={({ field }) => (
                                                        <Select
                                                            defaultValue={
                                                                currencies[0]
                                                            }
                                                            style={{
                                                                marginTop:
                                                                    "100px",
                                                            }}
                                                            className={`custom-select  ${
                                                                errors.currencyType &&
                                                                "error"
                                                            } ${
                                                                lang === "en"
                                                                    ? "en"
                                                                    : "ar"
                                                            }`}
                                                            {...field}
                                                            options={currencies}
                                                            placeholder={
                                                                lang === "en"
                                                                    ? "Account Type"
                                                                    : "اختار نوع الحساب"
                                                            }
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <Input
                                            type={type}
                                            id={id}
                                            {...register(id, rules)}
                                            placeholder={
                                                lang === "en"
                                                    ? en.placeholder
                                                    : ar.placeholder
                                            }
                                            error={errors[id] && true}
                                        />
                                    )}
                                    {errors[id] && (
                                        <InputErrorMessage
                                            msg={
                                                lang === "en"
                                                    ? en.validations[
                                                          errors[id].type
                                                      ]
                                                    : ar.validations[
                                                          errors[id].type
                                                      ]
                                            }
                                        />
                                    )}
                                </div>
                            )
                        )}
                        <div>
                            <Button disabled={isLoading} width={"180px"}>
                                {isLoading ? (
                                    <div
                                        className="spinner-border"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                ) : lang === "en" ? (
                                    "Add"
                                ) : (
                                    "اضافة"
                                )}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
