function UpLoadLabel ({error,children , ...otherAttr}) {
  return (
    <label
      className={`w-100 p-2 px-3 rounded-pill main-input d-block ${error ? "mt-2 mt-lg-4 mb-2 has-error" : "my-2 my-lg-4"}`}
      {...otherAttr}
    >
    {children}
    </label>
  );
}

export default UpLoadLabel;

