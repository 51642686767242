import { useSelector } from "react-redux";
import Modal from "../Modal/Modal";
import TrainerRadioBox from "../TrainerRadioBox/TrainerRadioBox";
import { Fragment } from "react";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";

function TrainerModal({itemid = 0}) {
    const { lang } = useSelector((state) => state.lang);
    const { token } = useSelector((state) => state.user);
    const navigate = useNavigate()
    const [selected, setSelected] = useState({});

    const { isLoading, data, error } = useAuthenticatedQuery({
        url: "api/trainer",
        queryKey: ["trainers"],
        config: {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    });
    useEffect(() => {
        if (isLoading) return;
        console.log(data);
        setSelected(data.result[0]);
    }, [data, isLoading]);
    if (isLoading) return <Loader />;

    return (
        <Modal
            title={lang === "en" ? "Delete item" : "اضافة مدرب للعنصر"}
            id="addTrainer"
            footer={
                <Fragment>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        {lang === "en" ? "Cancel" : "الغاء"}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                        onClick={() => navigate(`/addtrainer/${selected.trnId}&${itemid}`)}
                    >
                        {lang === "en" ? "Supmit" : "تأكيد"}
                    </button>
                </Fragment>
            }
        >
            <div className="modal-body">
                <TrainerRadioBox
                    data={data}
                    selected={selected}
                    setSelected={setSelected}
                />
            </div>
        </Modal>
    );
}

export default TrainerModal;
