import  { Fragment, forwardRef, useState } from "react";
import "./Input.css";
import { FaRegSquareFull , FaSquareFull } from "react-icons/fa6";
import { useSelector } from "react-redux";

const Input = forwardRef(({error ,name ,rounded = "pill",className, type, ...otherAttr}, ref) => {
  const {lang} = useSelector(state => state.lang)
 const [editIfPasswordType, setPasswordType] = useState(type)
const toggleShowPassword = ()=> {
    setPasswordType(editIfPasswordType === "text" ? "password" : "text")
}
const pos = lang === "en" ? {right: "20px"} : {left: "20px"}
  return (
    <Fragment>
    {name === "password" ? (
      <span style={{ ...pos , position : "absolute" , top: "50%" , transform : "translateY(-50%)" , cursor:"pointer" , color: "#8B4513"}} onClick={toggleShowPassword}>{editIfPasswordType === "password" ? <FaRegSquareFull /> : <FaSquareFull />}</span>
    ) : ""}
    <input
    ref={ref}
    name={name}
    type={editIfPasswordType}
    className={`w-100 p-2 px-3 rounded-${rounded} main-input d-block ${error ? "mt-2 mt-lg-4 mb-2 has-error" : "my-2 my-lg-4"} ${className}`}
    {...otherAttr}
    />
    </Fragment>
    
  );
});

export default Input;