import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./PrivacyPolicy.css";
import { useSelector } from "react-redux";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import Loader from "../../components/Loader/Loader";

function PrivacyPolicy() {
    const { lang } = useSelector((state) => state.lang);
    const { isLoading, data } = useAuthenticatedQuery({
        queryKey: ["privacy_policy"],
        url: "api/privacy_policy",
        config: {},
    });
    useEffect(() => {
        if (isLoading) return;
        const privacyPolicy = new DOMParser().parseFromString(
            lang === "en" ? data.result.textEn : data.result.textAr,
            "text/html"
        );
        document.getElementById("privacy").innerHTML = privacyPolicy.body.innerHTML
    }, [data, lang]);
    if (isLoading) return <Loader />;

    return (
        <Fragment>
            <section className="privacy-page page-title align-items-center justify-content-center d-flex">
                <h1 className="text-center fw-bold text-light">
                    {lang === "en" ? "Privacy Policy" : "سياسة الخصوصية"}
                </h1>
            </section>
            <section className="container py-5">
                <h2 className="fw-bold pt-3">
                    {lang === "en"
                        ? "Please read this Privacy Policy carefully before using our services"
                        : "يرجى قراءة سياسة الخصوصية هذه بعناية قبل استخدام خدماتنا"}
                    :
                </h2>
                <div id="privacy"></div>

                <Link
                    to="/register"
                    className="btn btn-primary d-block w-50 mx-auto"
                >
                    {lang === "en" ? "Register now" : "سجل أﻷن"}
                </Link>
            </section>
        </Fragment>
    );
}

export default PrivacyPolicy;
