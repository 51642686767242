import { NavbarBrand } from "react-bootstrap";
import { Link } from "react-router-dom";

function Logo() {
    const scrollTop = () => window["scrollTo"]({ top: 0, behavior: "smooth" });

    return (
        <Link to="/">
            <NavbarBrand className="navbar-brand" onClick={scrollTop}>
                <img
                    alt="Logo"
                    className="logo"
                    src="https://pngimg.com/uploads/camel/camel_PNG23404.png"
                />
            </NavbarBrand>
        </Link>
    );
}

export default Logo;
