import { Link } from "react-router-dom";
import { Fragment, useEffect } from "react";
import Slider from "react-slick";

import "./Trainers.css";
import { useSelector } from "react-redux";
import useAuthenticatedQuery from "../../hooks/useAuthenticatedQuery";
import Loader from "../../components/Loader/Loader";

const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 6,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    arrows: false,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};
function Trainers() {
    const { lang } = useSelector((state) => state.lang);
    const { logedIn, token } = useSelector((state) => state.user);

    const { isLoading, data, error } = useAuthenticatedQuery({
        url: "api/trainer",
        queryKey: ["trainers"],
        config: {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    });

    if (isLoading) return <Loader />;
console.log(data.result)
    return (
        <Fragment>
            <section className="trainers-page page-title align-items-center justify-content-center d-flex">
                <h1 className="text-center fw-bold text-light">
                    {lang === "en" ? "Trainers" : "المدربين"}
                </h1>
            </section>
            <section className="container pb-5 trainers">
                <div className="trainers-data container mb-5">
                    {data.result.length > 2 ? (
                        <Slider {...settings}>
                            {data.result.map((item) => (
                                <div
                                    className="card p-3"
                                    key={item.trnId}
                                >
                                    <div className="content d-flex justify-content-center flex-column align-items-center gap-3">
                                        <img
                                            style={{
                                                height: "150px",
                                                width: "100%",
                                            }}
                                            src={item.trnImgUrl}
                                            alt={item.fullName}
                                        />
                                        <h4 className="text-xl fw-bold">
                                            {lang === "en" ? (
                                                <span>Name</span>
                                            ) : (
                                                <span>الاسم</span>
                                            )}{" "}
                                            {" : "}
                                            {item.fullName}
                                        </h4>
                                        <h4 className="text-center">
                                            {lang === "en" ? (
                                                <span>Price</span>
                                            ) : (
                                                <span>السعر</span>
                                            )}{" "}
                                            {" : "}
                                            {item.rentmoney}
                                        </h4>
                                    </div>
                                    <Link className="btn btn-primary w-full"  to={`/trainers/${item.trnId}`} >{lang === "en" ? "more" : "المزيد"}</Link>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <div className="d-flex flex-wrap justify-content-around">
                            {data.result.map((item) => (
                                <div
                                    style={{
                                        minWidth: "300px",
                                        maxWidth: "100%",
                                    }}
                                    className="card p-3 m-5"
                                    key={item.trnId}
                                >
                                    <div className="content d-flex justify-content-center flex-column align-items-center gap-3">
                                        <img
                                            style={{
                                                height: "150px",
                                                width: "100%",
                                            }}
                                            src={item.trnImgUrl}
                                            alt={item.fullName}
                                        />
                                        <h4 className="text-xl fw-bold">
                                            {lang === "en" ? (
                                                <span>Name</span>
                                            ) : (
                                                <span>الاسم</span>
                                            )}{" "}
                                            {" : "}
                                            {item.fullName}
                                        </h4>
                                        <h4 className="text-center">
                                            {lang === "en" ? (
                                                <span>Price</span>
                                            ) : (
                                                <span>السعر</span>
                                            )}{" "}
                                            {" : "}
                                            {item.rentmoney}
                                        </h4>
                                    </div>
                                    <Link className="btn btn-primary w-full"  to={`/trainers/${item.trnId}`} >{lang === "en" ? "more" : "المزيد"}</Link>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </section>
        </Fragment>
    );
}

export default Trainers;
