import { useSelector } from "react-redux";

const Modal = ({  title, children,footer, id = "modal" }) => {
    const {lang} = useSelector(state => state.lang)
    return (
        <div className="modal fade" id={id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
              <button type="button" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close" style={(lang === "en" ? {right: "20px"} : {left: "10px"}) }></button>
            </div>
            <div className="modal-body">
              {children}
            </div>
            <div className="modal-footer">
             {footer}
            </div>
          </div>
        </div>
      </div>
    );
};

export default Modal;
